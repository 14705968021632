<template>
    <v-card class="mb-5" :loading="loadForm">
        <v-card-title>
            <span class="headline">Información cliente</span>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="!editForm">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        
                        v-bind="attrs"
                        v-on="on"
                        @click="editForm = true"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Editar información</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="saveTownhall"
                    >
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </template>
                <span>Guardar información</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Nombre Cliente"
                                v-model="townhall.name_townhall"
                                clearable
                                :disabled="!editForm"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Correo electrónico"
                                v-model="townhall.email"
                                :disabled="!editForm"
                                required
                                clearable
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Url cliente"
                                v-model="townhall.url"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Teléfono"
                                v-model="townhall.phone"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Facebook"
                                v-model="townhall.facebook_url"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Twitter"
                                v-model="townhall.twitter_url"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Instagram"
                                v-model="townhall.instagram_url"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Youtube"
                                v-model="townhall.youtube_url"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-img
                                lazy-src="https://picsum.photos/id/11/10/6"
                                max-height="150"
                                max-width="150"
                                :src="townhall.logo_img.url"
                            ></v-img>
                        </v-col>
                        <v-col>
                            <v-img
                                lazy-src="https://picsum.photos/id/11/10/6"
                                max-height="150"
                                max-width="150"
                                :src="townhall.landing_img.url"
                            ></v-img>                            
                        </v-col>
                        <v-col>
                                <v-img
                                lazy-src="https://picsum.photos/id/11/10/6"
                                max-height="150"
                                max-width="150"
                                :src="townhall.logo_townhall.url"
                            ></v-img>                            
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col>
                            <v-file-input
                                accept="image/*"
                                label="Logo"
                                @change="onPrincipalImageChange"
                                v-model="townhall.logo_img"
                                clearable
                            ></v-file-input>
                        </v-col>
                        <v-col>
                            <v-file-input
                                accept="image/*"
                                label="Banner"
                                @change="onBannerImage"
                                v-model="townhall.landing_img"
                            ></v-file-input>
                        </v-col>
                        <v-col>
                            <v-file-input
                                accept="image/*"
                                label="Imagen Footer"
                                @change="onFooterImage"
                                v-model="townhall.logo_townhall"
                            ></v-file-input>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col>
                            Color primario
                            <v-color-picker
                                v-model="townhall.primary_color"
                                mode="hexa"
                                :disabled="!editForm"
                            ></v-color-picker>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Título footer"
                                v-model="townhall.footer_title"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Url mapa del sitio"
                                v-model="townhall.url_site_map"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Url mapa argis"
                                v-model="townhall.url_argis_map"
                                :disabled="!editForm"
                                clearable
                                required>
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <label>Texto: Qué es el observatorio</label>
                            <quill-editor
                                ref="whatQuillEditor"
                                :disabled="!editForm"
                                v-model="townhall.what"
                                :options="editorOption"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <label>Texto: ¿A quién va dirigido?</label>
                            <quill-editor
                                ref="howQuillEditor"
                                :disabled="!editForm"
                                v-model="townhall.how"
                                :options="editorOption"
                            />
                        </v-col>                        
                    </v-row>
                    <v-row>
                        <v-col>
                            <label>Texto: Visión</label>
                            <quill-editor
                                ref="whyQuillEditor"
                                :disabled="!editForm"
                                v-model="townhall.why"
                                :options="editorOption"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <label>Texto: ¿Para qué sirve?</label>
                            <quill-editor
                                ref="whyQuillEditor"
                                :disabled="!editForm"
                                v-model="townhall.for"
                                :options="editorOption"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
    
</template>
<script>
 export default {
    data () {
      return {
        Stepper: 1,
        dialogDimension: false,
        dialogSegment: false,
        titleDimension: 'Nueva dimensión',
        id : '',
        townhall:[],
        tables:[],
        dimensions:[],
        chartData:{},
        barsData:{},
        manyTables:[],
        editForm: false,
        loadForm: false,
        editorOption: { 
        }
      }
    },
    computed:{
        logo_img(){
            if (!this.townhall.logo_img) return;
            return this.current_logo_url
        },
        landing_img(){
            if (!this.townhall.landing_img) return;
            return this.current_banner_url
        },
        townhall_logo(){
            if (!this.townhall.logo_townhall) return;
            return this.current_footer_url
        }
    },
    methods: {
        onPrincipalImageChange (e) {
        var files = e
        console.log(e)
        this.current_logo_url = URL.createObjectURL(files)
        console.log(this.current_principal_url)
        return this.createPrincipalImage(files)
      },
      createPrincipalImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          vm.townhall.logo_img = reader.result
        }
      },
      onBannerImage(e){
            var files = e
            this.current_banner_url = URL.createObjectURL(files)
            return this.createBannerImage(files)
        },
        createBannerImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          vm.townhall.landing_img = reader.result
        }
      },
    onFooterImage(e){
        var files = e
        this.current_footer_url = URL.createObjectURL(files)
        return this.createFooterImage(files)  
    },
    createFooterImage (file) {
        var reader = new FileReader()
        var vm = this
        reader.readAsDataURL(file);
        reader.onload = function () {
          vm.townhall.logo_townhall = reader.result
        }
    },
        
        async saveTownhall(){
            console.log(this.townhall)
            try{
                const updateTownhalls = await this.$http.put('townhalls/'+this.id, { data: {
                attributes:this.townhall}},{headers:{"Authorization": 'Bearer '+this.getCrypto()}})

                const colorTownhalls = await this.$http.put('townhalls/'+this.id, { data: {
                attributes:{primary_color:this.townhall.primary_color.hexa}}},{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
            }catch(error){

            }
            console.log('Actualizado',updateTownhalls)
            this.editForm = false;
        },
        getSegments(Dimension){
    
            this.dialogSegment = true;
        },
        async getTownhall(){
             try{
                    const townhalls = await this.$http.get('townhalls/'+this.id)
                  
                    this.townhall = townhalls.data
                }catch(error){
                //console.log(error)
                }
            
        },
        async getDimension(){
            //console.log('ingrese al twonhall')
             try{
                const dimension = await this.$http.get('dimensions/townhall/'+this.id)
                //const dimension = await this.$http.get('dimensions/townhall/'+this.id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                this.dimensions = dimension.data
                //console.log('arreglo dimension',this.dimensions)
            }catch(error){
                //console.log(error)
            }
            
        },
        async getTables(){
            //console.log('ingrese al twonhall')
             try{
                const tablas = await this.$http.get('tables/townhall/'+this.id)
                //const tablas = await this.$http.get('tables/townhall/'+this.id,{headers:{"Authorization": 'Bearer '+this.getCrypto()}})
                this.tables = tablas.data
                //console.log('arreglo tablas',this.tables)
                this.convertJson()
            }catch(error){
                //console.log(error)
            }
            
        },
        convertJson(){
        for(var j = 0 ; j< this.tables.length ; j++){
                //console.log('entre aca',this.tables.data)
        const beforeParse = this.tables[j].data
        const x = JSON.parse(beforeParse)
        const info = []
        const twoInfo = []
        for( var i = 0 ; i < (x.length-1) ; i++){
          info.push(x[i+1])
        }
        const y = x
        for( var i = 0 ; i < (x.length-1) ; i++){
          var r = [y[i+1][0],y[i+1][2]]
          twoInfo.push(r)
        }

        //console.log('esto es info ',info)
        //console.log('esto es 2 info ',twoInfo)
        this.chartData=info
        this.barsData=[{name:x[0][1],data:info},{name:x[0][2],data:twoInfo}]
        const newJson = JSON.stringify(x)
        //console.log(newJson.toString)


        const obj = {
            name:this.tables[j].name_table,
            type:this.tables[j].graph_type_id,
            chartData:this.chartData,
            barsData:this.barsData
        }
        this.manyTables.push(obj)
        }
        
        //console.log('Las tablas ',this.manyTables)
    },
    },
    beforeMount(){
        this.getTownhall()
        this.getDimension()
        this.getTables()
        

    },
    created(){
        if(this.$route.params.id==null){
            this.id=this.getClientSelect().id
        }else{
            this.id=this.$route.params.id
        }
    }
  }
</script>